<template>
  <div class="w-full">
    <div ref="header flex">
      <vs-row class="mb-4" vs-type="flex" vs-w="12">
        <vs-col vs-justify="left" vs-align="left" vs-w="10"> </vs-col>
        <vs-col vs-justify="left" class="flex" vs-align="left" vs-w="2">
          <ModalCadastrarPraga @update="getPragas()" />
        </vs-col>
      </vs-row>
    </div>
    <template>
      <div v-if="pragas.length > 0" class="items-grid-view vx-row match-height" :key="reRender">
        <div
          class="vx-col lg:w-1/4 sm:w-1/2 w-full"
          v-for="item in pragas"
          :key="item.id"
        >
          <item-grid-view :item="item" :parametros="parametros">
            <template slot="action-buttons">
              <div class="flex flex-wrap">
                <ModalEditarPraga @update="getPragas()" :praga="item" />
              </div>
            </template>
          </item-grid-view>
        </div>
      </div>
      <div v-else>
        <div class="con-colors">
            <ul>
              <li class="danger-box">
                <h1 class="p-5" style="color: white !important">Nenhuma praga cadstrada</h1>
              </li>
            </ul>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
import ModalCadastrarPraga from "./cadastro.vue";
import ModalEditarPraga from "./editar.vue";
export default {
  data() {
    return {
      pragas: {},
      reRender: 0,
      parametros: {}
    }
  },

  methods: {
    async getPragas() {
      await this.$vs.loading();
      try {
        this.pragas = await this.$http.get(`praga`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.reRender += await 1
        await this.$vs.loading.close();
      }
    }
  },
  components: {
    ItemGridView: () => import("./components/ItemGridView.vue"),
    ModalCadastrarPraga,
    ModalEditarPraga
  },
  async mounted() {
    this.parametros = await this.$parametros.get()
    await this.getPragas();
  }
};
</script>

<style>
.full {
  width: 100% !important;
}
</style>
