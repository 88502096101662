<template>
  <div class="w-full">
    <vs-button
      :disabled="!$atividades.permissoes.includes(14)"
      class="w-full item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer"
      @click="openModal()"
    >
      <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
      <span class="text-sm font-semibold ml-2">EDITAR</span>
    </vs-button>

    <SideBar
      title="EDITAR PRAGA"
      @hide="cancelar"
      :active="modalEditarPraga"
      v-if="open"
      textSucces="Atualizar"
      :disabled="!$atividades.permissoes.includes(15)"
      icon="edit"
      :item="praga"
      size="small"
      @excluir="exluirPraga()"
      @status="mudarStatusPraga()"
      @success="submitForm()"
    >
      <div slot="body">
        <div :id="'editarPraga' + praga.id" class="vs-con-loading__container">
          <vs-row  vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full"
                name="nome"
                v-validate="'required'"
                label="Nome"
                v-model="praga.nome"
              />
              <span class="text-danger text-sm" v-show="errors.has('nome')">{{
                $validators.empty
              }}</span>
            </vs-col>
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full mt-1"
                label="Apelido"
                name="apelido"
                v-validate="'required'"
                v-model="praga.apelido"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('apelido')"
                >{{ $validators.empty }}</span
              >
            </vs-col>
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full mt-1"
                label="Riscos"
                name="riscos"
                v-validate="'required'"
                v-model="praga.riscos"
              />
              <span class="text-danger text-sm" v-show="errors.has('riscos')">{{
                $validators.empty
              }}</span>
              <vs-input
                class="inputx w-full mt-1"
                label="Caracteristicas"
                v-model="praga.caracteristicas"
                name="caracteristicas"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('caracteristicas')"
                >{{ $validators.empty }}</span
              >
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="12"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="mt-10">
                <el-upload
                  accept=" .jpg, .jpeg, .png, .gif"
                  :key="reRender"
                  :action="link"
                  :auto-upload="true"
                  :on-error="erro"
                  :on-success="success"
                  class="w-full"
                  :limit="1"
                  :on-remove="removerFotoPraga"
                  :on-exceed="exceed"
                  :headers="headers"
                  :file-list="fileList"
                  name="image"
                  list-type="picture-card"
                  :on-preview="handlePreview"
                >
                  <i class="el-icon-plus " style="font-size:22px">Praga</i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </vs-col>
            <label style="color: #8c939d; font-size: .85rem;" class="m-0 pl-3"
              >Somente 1 Imagem</label
            ><br />
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: { praga: Object },
  data() {
    return {
      modalEditarPraga: false,
      error: false,
      originalData: {},
      open: false,
      parametros: {},
      //imagem
      dialogVisible: false,
      dialogImageUrl: null,
      fileList: [],
      link: "#",
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Origin": "*"
      },
      reRender: 0
    };
  },
  mounted() {},
  methods: {
    async openModal() {
      this.parametros = await this.$parametros.get();
      this._beforeEditingCache = Object.assign({}, this.praga);
      this.originalData = this.praga;
      this.open = await true;
      this.modalEditarPraga = await true;
      await this.$vs.loading({
        container: "#editarPraga" + this.praga.id,
        scale: 0.6
      });
      try {
        this.link = `${process.env.VUE_APP_URL}/uploadFotoPraga/${this.praga.id}`;
        await this.mountFileList()
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.reRender += await 1;
        await this.$vs.loading.close(
          "#editarPraga" + this.praga.id + " > .con-vs-loading"
        );
      }
    },
    async mountFileList() {
      this.fileList = await []
      if (this.praga.foto) {
        this.fileList = await [
          {
            name: `${this.praga.nome}`,
            url: `${this.parametros.storage_cdn_url}/${this.praga.foto}`
          }
        ];
      }
    },
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarPraga();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarPraga() {
      await this.$vs.loading({
        container: "#editarPraga" + this.praga.id,
        scale: 0.6
      });
      try {
        const res = await this.$http.put(`praga/${this.praga.id}`, this.praga);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da praga N°" + res.id
        };
        await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditarPraga = await false;
        await this.$vs.loading.close(
          "#editarPraga" + this.praga.id + " > .con-vs-loading"
        );
        await this.$emit("update");
      }
    },
    async exluirPraga() {
      await this.$vs.loading({
        container: "#editarPraga" + this.praga.id,
        scale: 0.6
      });
      try {
        await this.$http.delete(`praga/${this.praga.id}`);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão da praga N°" + this.praga.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalEditarPraga = await false;
        await this.$vs.loading.close(
          "#editarPraga" + this.praga.id + " > .con-vs-loading"
        );
      }
    },
    async mudarStatusPraga() {
      await this.$vs.loading({
        container: "#editarPraga" + this.praga.id,
        scale: 0.6
      });
      try {
        const res = await this.$http.put(`praga/${this.praga.id}`, {
          lixeira: !this.praga.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da praga N°" + res.id
        };
        await this.$logger(logData);
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditarPraga = false;
        this.$emit("update");
        await this.$vs.loading.close(
          "#editarPraga" + this.praga.id + " > .con-vs-loading"
        );
      }
    },
    async cancelar() {
      this.modalEditarPraga = await false;
      await this.$emit("update");
    },
    async handlePreview(file) {
      this.dialogImageUrl = await file.url;
      this.dialogVisible = await true;
    },
    async success(praga) {
      this.praga.foto = praga.foto
      this.$vs.notify({
        title: "Sucesso",
        text: "Praga Atualizada com Sucesso!",
        color: "success"
      });
    },
    async erro() {
      this.$vs.notify({
        title: "Ocorreu um erro",
        text: "Imagem da Praga não foi atualizada! Tente novamente mais tarde!",
        color: "danger"
      });
    },
    async exceed() {
      this.$vs.notify({
        title: "Tenha Calma!",
        text: "Somente 1 Imagem para a Praga!",
        color: "danger"
      });
    },
    async removerFotoPraga() {
     await this.$vs.loading({
        container: "#editarPraga" + this.praga.id,
        scale: 0.6
      });
      try {
        await this.$http.delete(`deleteFotoPraga/${this.praga.id}`);
        this.praga.foto = await null
        await this.mountFileList()
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#editarPraga" + this.praga.id + " > .con-vs-loading"
        );
      }
    },
  },
  components: components
};
</script>

<style>
.full {
  width: 100% !important;
}
</style>
