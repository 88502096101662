<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      class="mb-4"
      @func="openModal()"
    ></SuccessButton>
    <SideBar
      v-if="open"
      title="CADASTRAR NOVA PRAGA"
      @hide="close()"
      :active="modalCadastrarPraga"
      textSucces="Salvar"
      :disabled="!$atividades.permissoes.includes(15)"
      @success="praga.id ? saveClose() : submitForm()"
      size="small"
    >
      <div slot="body">
        <div id="cadastrarPraga" class="vs-con-loading__container">
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="12" class="mt-1">
              <vs-input
                class="inputx w-full"
                name="nome"
                v-validate="'required'"
                label="Nome"
                v-model="praga.nome"
              />
              <span class="text-danger text-sm" v-show="errors.has('nome')">
                {{
                $validators.empty
                }}
              </span>
            </vs-col>
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full mt-1"
                label="Apelido"
                name="apelido"
                v-validate="'required'"
                v-model="praga.apelido"
              />
              <span class="text-danger text-sm" v-show="errors.has('apelido')">
                {{
                $validators.empty
                }}
              </span>
            </vs-col>
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full mt-1"
                label="Riscos"
                name="riscos"
                v-validate="'required'"
                v-model="praga.riscos"
              />
              <span class="text-danger text-sm" v-show="errors.has('riscos')">
                {{
                $validators.empty
                }}
              </span>
              <vs-input
                class="inputx w-full mt-1"
                label="Caracteristicas"
                v-model="praga.caracteristicas"
                name="caracteristicas"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('caracteristicas')"
              >{{ $validators.empty }}</span>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-align="center" vs-justify="center" vs-w="12" v-if="praga.id">
            <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
              <div class="mt-10">
                <el-upload
                  accept=".jpg, .jpeg, .png, .gif"
                  :action="link"
                  :auto-upload="true"
                  :on-error="erro"
                  :on-success="success"
                  class="w-full"
                  :key="reRender"
                  :on-remove="removerFotoPraga"
                  :on-exceed="exceed"
                  :headers="headers"
                  :limit="1"
                  name="image"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                >
                  <i class="el-icon-plus" style="font-size:22px">Praga</i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
              </div>
            </vs-col>
            <label style="color: #8c939d; font-size: .85rem;" class="m-0 pl-3">Somente 1 Imagem</label>
            <br />
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      praga: {},
      modalCadastrarPraga: false,
      error: false,
      open: false,
      //imagem
      dialogVisible: false,
      dialogImageUrl: null,
      link: "#",
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Origin": "*",
      },
      reRender: 0,
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.cadastrarPraga();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async openModal() {
      await this.errors.clear();
      this.open = await true;
      this.modalCadastrarPraga = await true;
    },
    async closeModal() {
      this.modalCadastrarPraga = await false;
      await this.$emit("update");
      this.praga = await {};
    },
    async cadastrarPraga() {
      await this.$vs.loading({ container: "#cadastrarPraga", scale: 0.6 });
      try {
        this.praga.foto = 0;
        this.praga = await this.$http.post("praga", this.praga);
        this.link = `${process.env.VUE_APP_URL}/uploadFotoPraga/${this.praga.id}`;
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro da praga N°" + this.praga.id,
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.reRender += 1;
        await this.$vs.loading.close("#cadastrarPraga > .con-vs-loading");
      }
    },
    async saveClose() {
      await this.$vs.loading({ container: "#cadastrarPraga", scale: 0.6 });
      try {
        await this.$http.put(`praga/${this.praga.id}`, this.praga);
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalCadastrarPraga = await false;
        await this.$emit("update");
        this.praga = await {};
        await this.$vs.loading.close("#cadastrarPraga > .con-vs-loading");
      }
    },
    async close() {
      this.modalCadastrarPraga = false;
      await this.$emit("update");
      this.praga = {};
    },
    async success(praga) {
      this.praga = praga;
      this.$vs.notify({
        title: "Sucesso",
        text: "Praga Salva com Sucesso!",
        color: "success",
      });
      await this.$emit("update");
    },
    async erro() {
      this.$vs.notify({
        title: "Ocorreu um erro",
        text: "Imagem da Praga não foi salva! Tente novamente mais tarde!",
        color: "danger",
      });
    },
    async handlePictureCardPreview(file) {
      this.dialogImageUrl = await file.url;
      this.dialogVisible = await true;
    },
    async exceed() {
      this.$vs.notify({
        title: "Tenha Calma!",
        text: "Somente 1 Imagem para a Praga!",
        color: "danger",
      });
    },
    async removerFotoPraga() {
      await this.$vs.loading({ container: "#cadastrarPraga", scale: 0.6 });
      try {
        this.praga.foto = await this.$http.delete(
          `deleteFotoPraga/${this.praga.id}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#cadastrarPraga > .con-vs-loading");
      }
    },
  },
  components: components,
};
</script>

<style></style>
